@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;900&display=swap");
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background: #0250c5;
	background: -webkit-linear-gradient(bottom, #0250c5, #d43f8d);
	background: -o-linear-gradient(bottom, #0250c5, #d43f8d);
	background: -moz-linear-gradient(bottom, #0250c5, #d43f8d);
	background: linear-gradient(to left, #0250c5, #d43f8d);
	z-index: 1;
	font-family: "Montserrat", sans-serif;
}

.wrapper::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url("./img-01.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.heading {
	color: white;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 900;
}

.sub-head {
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
.login-temp {
	z-index: 1;
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;900&display=swap);
body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#title {
	color: white;
	font-weight: 600;
}
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(167, 167, 167);
	border-radius: 10px;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background: #0250c5;
	background: linear-gradient(to left, #0250c5, #d43f8d);
	z-index: 1;
	font-family: "Montserrat", sans-serif;
}

.wrapper::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url(../../static/media/img-01.66fe947f.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.heading {
	color: white;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 900;
}

.sub-head {
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
.login-temp {
	z-index: 1;
}

.main-navbar {
	display: flex;
	min-height: 92vh;
	flex-direction: column;
	justify-content: space-between;
	background: aliceblue;
}
#title {
	color: white;
	font-weight: 600;
}

.profile {
	color: white !important;
	background: rgb(255, 35, 108) !important;
	width: 60px !important;
	height: 60px !important;
	font-size: 30px !important;
}

.profile-body {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}
.view-loader {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 20vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 75vh;
}
.measure2 {
	background: rgb(255, 255, 255);
}
.measure1 {
	background: aliceblue;
}
.measure {
	background: rgba(228, 228, 228, 0.541);
}

.dim {
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

